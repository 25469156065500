@font-face {
  font-family: 'paasplatico';
  src:  url('../../../../fonts/paasplatico.eot?qm6rts');
  src:  url('../../../../fonts/paasplatico.eot?qm6rts#iefix') format('embedded-opentype'),
    url('../../../../fonts/paasplatico.ttf?qm6rts') format('truetype'),
    url('../../../../fonts/paasplatico.woff?qm6rts') format('woff'),
    url('../../../../fonts/paasplatico.svg?qm6rts#paasplatico') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'paasplatico' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-minio:before {
  content: "\e900";
}
.icon-airbyte:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e902";
}
.icon-upload:before {
  content: "\e903";
}
.icon-download:before {
  content: "\e904";
}
.icon-diagonal-arrow-down:before {
  content: "\e905";
}
.icon-diagonal-arrow-top:before {
  content: "\e906";
}
.icon-refresh:before {
  content: "\e907";
}
.icon-add:before {
  content: "\e908";
}
.icon-up-arrow:before {
  content: "\e909";
}
.icon-delete:before {
  content: "\e90a";
}
.icon-down-arrow:before {
  content: "\e90b";
}
.icon-confirm:before {
  content: "\e90c";
}
.icon-right-arrow:before {
  content: "\e90d";
}
.icon-up-pike:before {
  content: "\e90e";
}
.icon-down-pike:before {
  content: "\e90f";
}
.icon-left-pike:before {
  content: "\e910";
}
.icon-left-arrow:before {
  content: "\e911";
}
.icon-right-pike:before {
  content: "\e912";
}
.icon-rstudio:before {
  content: "\e913";
}
.icon-nifireg-min:before {
  content: "\e914";
}
.icon-hive:before {
  content: "\e915";
}
.icon-spark:before {
  content: "\e916";
}
.icon-prometheus:before {
  content: "\e917";
}
.icon-sckitlearn:before {
  content: "\e918";
}
.icon-nifireg:before {
  content: "\e919";
}
.icon-kafkamanager:before {
  content: "\e91a";
}
.icon-nifi:before {
  content: "\e91b";
}
.icon-mlflow:before {
  content: "\e91c";
}
.icon-timescaledb:before {
  content: "\e961";
}
.icon-datahub:before {
  content: "\e91d";
}
.icon-trino:before {
  content: "\e91e";
}
.icon-mlflow-min:before {
  content: "\e91f";
}
.icon-kylin:before {
  content: "\e920";
}
.icon-jupyter:before {
  content: "\e921";
}
.icon-kafka:before {
  content: "\e922";
}
.icon-hbase:before {
  content: "\e923";
}
.icon-grafana:before {
  content: "\e924";
}
.icon-hadoop:before {
  content: "\e925";
}
.icon-solr:before {
  content: "\e926";
}
.icon-hue-min:before {
  content: "\e927";
}
.icon-druid:before {
  content: "\e928";
}
.icon-zookeeper:before {
  content: "\e929";
}
.icon-nodered:before {
  content: "\e92a";
}
.icon-superset:before {
  content: "\e92b";
}
.icon-thingsboard:before {
  content: "\e92c";
}
.icon-chirpstack:before {
  content: "\e92d";
}
.icon-emqx:before {
  content: "\e92e";
}
.icon-laino-theme:before {
  content: "\e92f";
}
.icon-laino-upgrade:before {
  content: "\e930";
}
.icon-laino-kubernetes:before {
  content: "\e931";
}
.icon-apacheranger:before {
  content: "\e932";
}
.icon-alertmanager:before {
  content: "\e933";
}
.icon-flink:before {
  content: "\e934";
}
.icon-airflow:before {
  content: "\e935";
}
.icon-laino-data-sql:before {
  content: "\e936";
}
.icon-laino-datalake:before {
  content: "\e937";
}
.icon-laino-data-nosql:before {
  content: "\e938";
}
.icon-laino-data-iot:before {
  content: "\e939";
}
.icon-laino-data-flow:before {
  content: "\e93a";
}
.icon-laino-data-storage:before {
  content: "\e93b";
}
.icon-laino-data-stream:before {
  content: "\e93c";
}
.icon-laino-data-view:before {
  content: "\e93d";
}
.icon-laino-data-vector:before {
  content: "\e95e";
}
.icon-laino-data-llm:before {
  content: "\e95f";
}
.icon-laino-data-governance:before {
  content: "\e950";
}
.icon-laino-data-learn:before {
  content: "\e93e";
}
.icon-postgresql:before {
  content: "\e93f";
}
.icon-laino-user:before {
  content: "\e940";
}
.icon-terminal-min:before {
  content: "\e941";
}
.icon-configuration:before {
  content: "\e942";
}
.icon-api:before {
  content: "\e943";
}
.icon-laino-user-search:before {
  content: "\e944";
}
.icon-laino-user-role:before {
  content: "\e945";
}
.icon-laino-company-minilogo:before {
  content: "\e946";
}
.icon-api-big:before {
  content: "\e947";
}
.icon-copy:before {
  content: "\e948";
}
.icon-info:before {
  content: "\e949";
}
.icon-laino-notification:before {
  content: "\e94a";
}
.icon-langflow:before {
  content: "\e960";
}
.icon-langfuse:before {
  content: "\e94b";
}
.icon-flowiseai:before {
  content: "\e94c";
}
.icon-milvus:before {
  content: "\e94d";
}
.icon-couchdb:before {
  content: "\e94e";
}
.icon-redis:before {
  content: "\e94f";
}
.icon-influxdb:before {
  content: "\e951";
}
.icon-laino-company-logo:before {
  content: "\e952";
}
.icon-laino-company-create:before {
  content: "\e953";
}
.icon-laino-user-invite:before {
  content: "\e954";
}
.icon-rabbitmq:before {
  content: "\e955";
}
.icon-bin:before {
  content: "\e956";
}
.icon-terminal-inverse:before {
  content: "\e957";
}
.icon-terminal:before {
  content: "\e958";
}
.icon-alert:before {
  content: "\f138";
}
.icon-cube:before {
  content: "\e962";
}
.icon-eye-closed:before {
  content: "\e963";
}
.icon-star:before {
  content: "\e959";
}
.icon-tag:before {
  content: "\e95a";
}
.icon-conf:before {
  content: "\e95b";
}
.icon-key:before {
  content: "\e95c";
}
.icon-eye:before {
  content: "\e95d";
}
